import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
//import { graphql } from 'react-apollo';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

import AccountIcon from 'components/LightIcons/AccountCircleLight';

import ProfileContent from './components/ProfileContent/ProfileContent';

//import userQu from 'LixaniAPI/userQu';


const styles = theme => ({
	root: {
		display: 'inherit',
		'&:hover': {
			color: 'hsla(225,75%,25%,1)',
			[theme.breakpoints.up('sm')]: {
				color: 'hsla(225,75%,25%,0.75)',
			}
		}
	},
	rootOpen: {
		color: 'hsla(225,75%,25%,1)',
		[theme.breakpoints.up('sm')]: {
			color: 'hsla(225,75%,25%,0.75)',
		}
	},
	iconButton: {
		height: 40,
		width: 40,
		[theme.breakpoints.up('sm')]: {
			margin: '0 4px',
		}
	},
	popoverPaper: {
		//borderRadius: 3,
	},
	paper: {
		width: 'calc(100vw - 32px)',
		position: 'relative',
		borderRadius: 3,
		[theme.breakpoints.up('sm')]: {
			width: 320,
		},
	},
	icon: {},
	header: {
		background: theme.palette.primary.gradient,
		color: '#fff',
		height: 160,
		padding: 16,
	},
	content: {
		minHeight: 240,
		padding: 16,
	},
	drawerPaper: {
		width: 280,
	},
});

class ProfileButton extends Component {
	state = {
		open: false,
		anchorEl: null,
	}

	handleOpen = () => {
		this.setState({
			open: !this.state.open,
			// eslint-disable-next-line
			anchorEl: findDOMNode(this.profileButton),
		});
	};

	handleClose = () => {
		if (!this.state.open) {
			return;
		}

		this.timeout = setTimeout(() => {
			this.setState({ open: false });
		});
	};

	profileButton = null;

	render() {
		const { classes, user } = this.props;
		const { open, anchorEl } = this.state;

		const profileContent = (
			<ProfileContent user={user} onClose={this.handleClose} />
		)

		return (
			<div className={clsx(classes.root, open && classes.rootOpen)}>
				<IconButton
					className={classes.iconButton}
					color="inherit"
					ref={node => {this.profileButton = node}}
					onClick={this.handleOpen}
				>
					<AccountIcon className={classes.icon} />
				</IconButton>
				<Hidden smDown>
					<Popover
						open={open}
						anchorEl={anchorEl}
						anchorReference="anchorEl"
						anchorPosition={{ top: 0, left: 0 }}
						onClose={this.handleClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						classes={{ paper: classes.popoverPaper }}
					>
						{profileContent}
					</Popover>
				</Hidden>
				<Hidden mdUp>
					<Drawer
						anchor="right"
						classes={{ paper: classes.drawerPaper }}
						open={open}
						onClose={this.handleClose}
					>
						{profileContent}
					</Drawer>
				</Hidden>
			</div>
		);
	}
}

//const UserQu =
/* const ProfileButtonCompose =
  graphql(userQu, {
    options: {
      fetchPolicy: 'cache-and-network',
    },
    props: (props) => ({
      user: props.data.getUser,
    })
})(ProfileButton) */
	
ProfileButton.defaultProps = {
	user: {},
}

ProfileButton.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
}

export default withStyles(styles, { withTheme: true })(ProfileButton);