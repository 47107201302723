Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('@material-ui/core/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M7,5v2H5V5H7 M8,4H4v4h4V4L8,4zM13,5v2h-2V5H13 M14,4h-4v4h4V4L14,4zM19,5v2h-2V5H19 M20,4h-4v4h4V4L20,4zM7,10.9v2H5v-2H7 M8,9.9H4v4h4V9.9L8,9.9zM13,10.9v2h-2v-2H13 M14,9.9h-4v4h4V9.9L14,9.9zM19,10.9v2h-2v-2H19 M20,9.9h-4v4h4V9.9L20,9.9zM7,17v2H5v-2H7 M8,16H4v4h4V16L8,16zM13,17v2h-2v-2H13 M14,16h-4v4h4V16L14,16zM19,17v2h-2v-2H19 M20,16h-4v4h4V16L20,16z'})
  );
};