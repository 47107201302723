import moment from 'moment';

function momentDiffTime(a, b, status) {
	const mA = moment(a);
	let mB = moment();
	if (status === "OUT") {
		mB = moment(b);
	}
	const duration = moment.duration(mB.diff(mA));
	const hours = parseInt(duration.asHours(), 10);
	const minutes = ('0' + parseInt(duration.asMinutes(), 10)%60).slice(-2);
	return hours + ':' + minutes;
}

export default momentDiffTime;