const RECORD_STATUS = Object.freeze({
  IN: 'IN',
  OUT: 'OUT',
});
const RECORD_TYPE = Object.freeze({
  ABSENCE: 'ABSENCE',
	BENEFIT: 'BENEFIT',
	BREAK: 'BREAK',
	ITEM: 'ITEM',
	RIDE: 'RIDE',
  SHIFT: 'SHIFT',
	WORK: 'WORK',
})

export { RECORD_STATUS, RECORD_TYPE };