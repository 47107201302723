import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Config from 'Config';

import CloseIcon from 'mdi-material-ui/Close'

const styles = theme => ({
	root: {
		background: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: 320,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	header: {
		alignItems: 'center',
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		height: 48,
		justifyContent: 'center',
		position: 'relative',
	},
	closeBtn: {
		color: theme.palette.common.white,
		height: 40,
		left: 4,
		position: 'absolute',
		top: 4,
		width: 40,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		},
	},
	currentTime: {
		fontSize: '0.87rem',
		marginBottom: theme.spacing(2),
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		padding: theme.spacing(2),
	},
	timerIcon: {
		height: 64,
		width: 64,
	},
	totalHours: {
		fontSize: '1.5rem',
	},
});

class ProfileContent extends Component {

	onLogout = () => {
		window.location = Config.SSO_LOGOUT_URL + encodeURIComponent(window.location);
	}

	render() {
		const { classes, user } = this.props;

		//console.log('ProfileButton user:', user);

		return (
			<div>
				<div className={classes.header}>
					<IconButton className={classes.closeBtn} onClick={this.props.onClose}>
						<CloseIcon />
					</IconButton>
					{user.firstName} {user.lastName}
				</div>
				<div className={classes.content}>
					<Button variant="outlined" color="primary" onClick={this.onLogout}>
						Kirjaudu ulos
					</Button>
				</div>
			</div>
		);
	}
}

ProfileContent.defaultProps = {
  classes: {},
	onClose: () => null,
  user: {},
};

ProfileContent.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProfileContent);