const Config = {
  AWS_ACCOUNT_ID: process.env.REACT_APP_AWS_ACCOUNT_ID,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_AUTH_TYPE: process.env.REACT_APP_AWS_AUTH_TYPE,
  BUILD_TS: process.env.REACT_APP_BUILD_TS,
  FILES_DOMAIN: process.env.REACT_APP_FILES_DOMAIN,
  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  LIXANI_ADMIN_URL: process.env.REACT_APP_LIXANI_ADMIN_URL,
  LIXANI_WORK_URL: process.env.REACT_APP_LIXANI_WORK_URL,
  MY_LIXANI_URL: process.env.REACT_APP_MY_LIXANI_URL,
  SSO_TOKEN_URL: process.env.REACT_APP_SSO_TOKEN_URL,
  SSO_LOGIN_URL: process.env.REACT_APP_SSO_LOGIN_URL,
  SSO_LOGOUT_URL: process.env.REACT_APP_SSO_LOGOUT_URL
}

export default Config
