import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import CloseIcon from 'mdi-material-ui/Close';

const styles = theme => ({
	root: {
		backgroundColor: 'white',
		boxShadow: theme.shadows[3],
		height: 24,
		width: 24,
		position: 'absolute',
		marginTop: '-30px',
		marginLeft: '28px',
		'&:active, &:hover': {
			backgroundColor: 'white',
		}
	},
	icon: {
		height: 16,
		width: 16,
	},
});

function dialogCloseBtn (props) {
	const { classes } = props;

	return (
		<IconButton className={classes.root} onClick={props.onClick}>
			<CloseIcon className={classes.icon} />
		</IconButton>
	);
}

dialogCloseBtn.defaultProps = {
	classes: {},
	onClick: () => null,
}

dialogCloseBtn.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(dialogCloseBtn);