import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import LogoutIcon from 'mdi-material-ui/LogoutVariant';

import momentDiffTime from 'utils/momentDiffTime';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import PaperHeaderCloseButton from 'components/PaperHeaderCloseButton/PaperHeaderCloseButton';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		position: 'initial',
		overflow: 'initial',
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
	},
}));

function ClockOutDialog(props) {

	const classes = useStyles();
	const { record } = props;
	const { t } = useTranslation('common');

	const handleSave = () => {
		const description = (this.inputRecordDescription && this.inputRecordDescription.value) || null;
		props.onClockOut({ ...props.record, description: description });
		props.onClose();
	}

	const dialogCloseBtn = (
		<PaperHeaderCloseButton onClick={props.onClose} />
	);

	return (
		<Dialog
			aria-labelledby="clock-out-dialog"
			classes={{ paper: classes.dialogPaper }}
			disableBackdropClick
			fullWidth
			maxWidth="xs"
			onClose={props.onClose}
			open={props.open}
		>
			<PaperHeader
				icon={<LogoutIcon />}
				className={classes.editHeader}
				title={t('record.clock_out')}
				paperColor="primary"
				buttons={dialogCloseBtn}
			/>
			<DialogContent className={classes.dialogContent}>
				<List dense disablePadding>
					<ListItem disableGutters dense divider>
						<ListItemText
							primary={record.project.name}
							secondary={t('project.project')}
						/>
					</ListItem>
					<ListItem disableGutters dense divider>
						<ListItemText
							primary={moment(record.clockInAt).format('L LT')}
							secondary={t('datetime.start')}
						/>
					</ListItem>
					<ListItem disableGutters dense divider>
						<ListItemText
							primary={momentDiffTime(record.clockInAt)}
							secondary={t('datetime.total')}
						/>
					</ListItem>
				</List>
				<TextField
					className={classes.textField}
					defaultValue=""
					fullWidth
					inputRef={input => {this.inputRecordDescription = input}}
					label={t('common.description')}
					margin="dense"
					multiline
					type="text"
				/>
			</DialogContent>
			<Divider />
			<DialogActions className={classes.dialogActions}>
				<Button onClick={props.onClose} color="primary">
					{t('common.cancel')}
				</Button>
				<Button
					onClick={handleSave}
					color="primary"
				>
					{t('record.clock_out')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ClockOutDialog.defaultProps = {
	classes: {},
	onClockOut: () => null,
	onClose: () => null,
	open: false,
}

ClockOutDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	onClockOut: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	record: PropTypes.object.isRequired,
}

export default ClockOutDialog;