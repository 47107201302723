import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'scenes/App';
import Loading from 'components/Loading/Loading';
import { I18nextProvider } from 'react-i18next';
import AppSyncClient from 'lixani-lib-graphql';
import { withApollo } from 'react-apollo';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import rootReducer from './store/reducer';

import registerServiceWorker from './registerServiceWorker';

import i18n from './i18n';
import Config from './Config';

const store = createStore(rootReducer);

function AppInit(props) {

	useEffect(() => {
    // forcing finnish for now
		i18n.on('initialized', () => {
			i18n.changeLanguage('fi');
		})
	}, []);
	
  return (<App />)
}

const AppInitWithApollo = withApollo(AppInit);

ReactDOM.render(
  <AppSyncClient
    graphqlEndpoint={Config.GRAPHQL_ENDPOINT}
    awsRegion={Config.AWS_REGION}
    awsAuthType={Config.AWS_AUTH_TYPE}
    ssoTokenUrl={Config.SSO_TOKEN_URL}
    ssoLoginUrl={Config.SSO_LOGIN_URL}
    build={Config.BUILD_TS}
    filesDomain={Config.FILES_DOMAIN}
  >
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <AppInitWithApollo />
        </I18nextProvider>
      </Suspense>
    </Provider>
  </AppSyncClient>,
  document.getElementById('root')
);
registerServiceWorker();