Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('@material-ui/core/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M12,3c5,0,9,4,9,9c0,5-4,9-9,9c-5,0-9-4-9-9C3,7,7,3,12,3 M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C22,6.5,17.5,2,12,2L12,2zM12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S10.9,6,12,6 M12,5c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,5,12,5L12,5zM12,14c3.5,0,4.7,1.1,4.9,1.8c-0.5,0.7-2.2,2.5-5,2.5c-2.8,0-4.4-1.8-5-2.5C7.3,15.1,8.5,14,12,14 M12,13c-5.5,0-6,2.5-6,3c0,0,2,3.3,6,3.3s6-3.3,6-3.3C18,15.5,17.5,13,12,13L12,13z'})
  );
};