import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

//import BellIcon from 'mdi-material-ui/Bell';
import BellIcon from 'components/LightIcons/BellLight';
import CloseIcon from 'mdi-material-ui/Close';
import Cog from 'mdi-material-ui/Cog';
import ClearIcon from 'mdi-material-ui/NotificationClearAll';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inherit',
		'&:hover': {
			color: 'hsla(225,75%,25%,1)',
			[theme.breakpoints.up('sm')]: {
				color: 'hsla(225,75%,25%,0.75)',
			}
		}
	},
	rootOpen: {
		color: 'hsla(225,75%,25%,1)',
		[theme.breakpoints.up('sm')]: {
			color: 'hsla(225,75%,25%,0.75)',
		}
	},
	iconButton: {
		height: 40,
		width: 40,
		[theme.breakpoints.up('sm')]: {
			margin: '0 4px',
		}
	},
	paper: {
		backgroundColor: theme.palette.background.default,
		width: 'calc(100vw - 32px)',
		position: 'relative',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
			minWidth: 400,
		},
	},
	listHeader: {
		whiteSpace: 'nowrap',
		display: 'flex',
		justifyContent: 'space-between'
	},
	listItem: {
		padding: '5px 0',
	},
	noteList: {
		paddingTop: 0,
		paddingRight: 12,
		paddingLeft: 12,
	},
	noteItemPaper: {
		padding: '8px 12px',
		width: '100%',
	},
	cogButton: {
	}
}));

function NoteButton() {

	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setOpen(!open);
		// eslint-disable-next-line
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		if (!open) {
			return;
		}

		setTimeout(() => {
			setOpen(false);
		});
	};

	return (
		<div className={clsx(classes.root, open && classes.rootOpen)} style={{ display: 'none' }}>
			<IconButton
				className={classes.iconButton}
				color="inherit"
				onClick={handleClick}
			>
				<BellIcon />
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				anchorReference="anchorEl"
				anchorPosition={{ top: 0, left: 0 }}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				classes={{ paper: classes.popoverPaper }}
			>
				<Paper className={classes.paper}>
					<div className={classes.listHeader}>
						<IconButton className={classes.cogButton}>
							<Cog />
						</IconButton>
						<IconButton>
							<ClearIcon />
						</IconButton>
					</div>
					<List className={classes.noteList}>
						<ListItem className={classes.listItem} disableGutters={true}>
							<Paper className={classes.noteItemPaper} elevation={1}>
								<ListItemText primary="Test notification" secondary="12.2.2018 16:15" />
								<ListItemSecondaryAction>
									<IconButton className={classes.noteClose} aria-label="Remove notification">
										<CloseIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</Paper>
						</ListItem>
						<ListItem className={classes.listItem} disableGutters={true}>
							<Paper className={classes.noteItemPaper} elevation={1}>
								<ListItemText primary="Test notification" secondary="12.2.2018 16:15" />
								<ListItemSecondaryAction>
									<IconButton className={classes.noteClose} aria-label="Remove notification">
										<CloseIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</Paper>
						</ListItem>
					</List>
				</Paper>
			</Popover>
		</div>
	);
}

NoteButton.propTypes = {
	classes: PropTypes.object,
}

export default NoteButton;