import React from 'react';

const styles = {
	root: {
		enableBackground: 'new 0 0 240 240',
	},
	st0: {
		fill: '#53A7CE',
	},
	st2: {
		fill: '#09789D',
		filter: 'url(#black-glow)',
	},
	st3: {
		fill: '#F2F2F2',
	},
	st7: {
		opacity: 0.2,
		fill: 'url(#SVGID_3_)',
		mixBlendingMode: 'multiply',
	},
	st8: {
		fill: '#FFFFFF',
	},
	st11: {
		fill: '#FFFFFF',
		filter: 'url(#black-glow)',
	},
	st12: {
		fill: '#00557A',
	},
	st13: {
		filter:'url(#black-glow)',
	},
	st14: {
		fill:'#09789D',
	},
};

function MyLixani () {

	return (
		<svg style={styles.root}
				xmlns="http://www.w3.org/2000/svg" 
				x="0px" y="0px" width="48px" height="48px" viewBox="0 0 240 240">
			<filter id="black-glow">
				<feColorMatrix  type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0">
				</feColorMatrix>
				<feGaussianBlur  stdDeviation="2.5" result="coloredBlur"></feGaussianBlur>
				<feMerge>
					<feMergeNode  in="coloredBlur"></feMergeNode>
					<feMergeNode  in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>

			<g>
				<g style={styles.st13}>
					<polygon style={styles.st14} points="140,30 110,60 130,60 160,30 		"/>
				</g>
				<path style={styles.st2} d="M185,190c0,8.3-6.7,15-15,15H70c-8.3,0-15-6.7-15-15V60c0-8.3,6.7-15,15-15h100c8.3,0,15,6.7,15,15V190z
					M140,57.5c0-1.4-1.1-2.5-2.5-2.5h-35c-1.4,0-2.5,1.1-2.5,2.5v3c0,1.4,1.1,2.5,2.5,2.5h35c1.4,0,2.5-1.1,2.5-2.5V57.5z"/>
				<path style={styles.st12} d="M185,85H55V60c0-8.3,6.7-15,15-15h100c8.3,0,15,6.7,15,15V85z M140,57.5c0-1.4-1.1-2.5-2.5-2.5h-35
					c-1.4,0-2.5,1.1-2.5,2.5v3c0,1.4,1.1,2.5,2.5,2.5h35c1.4,0,2.5-1.1,2.5-2.5V57.5z"/>
				<g style={styles.st13}>
					<polygon style={styles.st0} points="130,60 100,30 80,30 110,60 		"/>
				</g>
			</g>
			<g>
				<g>
					<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="167.6074" y1="213.8799" x2="97.9506" y2="93.2307">
						<stop  offset="0" style={{ stopColor: '#000000', stopOpacity: 0 }} />
						<stop  offset="1" style={{ stopColor: '#000000'}} />
					</linearGradient>
					<path style={styles.st7} d="M80.5,103.3L80,178.6l26.2,26.4H170c8.3,0,15-6.7,15-15v-62.5L159.5,102L80.5,103.3z"/>
				</g>
			</g>
			<g>
				<path style={styles.st11} d="M160,176c0,2.2-1.8,4-4,4H83c-2.2,0-4-1.8-4-4v-72c0-2.2,1.8-4,4-4h73c2.2,0,4,1.8,4,4V176z M155,106
					c0-0.6-0.4-1-1-1H85c-0.6,0-1,0.4-1,1v68c0,0.6,0.4,1,1,1h69c0.6,0,1-0.4,1-1V106z"/>
				<g>
					<path style={styles.st3} d="M64.2,55.9c0,0,0.2-0.9,1.5-0.9c1.3,0,2.2,0,2.2,0s1.2,0,1.2,0.9s0,13,0,13.6c0,0.9,0.4,3.2,8.9,3.2
						c8.5,0,10.4,0,10.9,0c0.5,0,1.1,0.6,1.1,2c0,1.4-0.5,2-1.1,2c-0.6,0-7.6,0-10.9,0c-3.3,0-13.8,0.1-13.8-6.6
						C64.2,62.3,64.2,55.9,64.2,55.9z"/>
				</g>
				<g style={styles.st13}>
					<path style={styles.st8} d="M95,170v-7.5c0,0,5-10,25-10s25,10,25,10v7.5H95z"/>
				</g>
				<g style={styles.st13}>
					<circle style={styles.st8} cx="120" cy="132.5" r="12.5"/>
				</g>
			</g>
		</svg>
	)
}

export default MyLixani;