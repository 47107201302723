import React from 'react';

const styles = {
	root: {
		enableBackground: 'new 0 0 240 240',
	},
	st2: { fill: '#256193',},
	st7: { fill: '#FFFFFF',},
	st12: { filter: 'url(#black-glow)',},
	st13: { fill: '#3D75AD',},
};

function Work () {

	return (
		<svg style={styles.root}
				xmlns="http://www.w3.org/2000/svg" 
				x="0px" y="0px" width="48px" height="48px" viewBox="0 0 240 240">
    <filter id="black-glow">
      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feGaussianBlur stdDeviation={2.5} result="coloredBlur" />
      <feMerge>
        <feMergeNode in="coloredBlur" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
    <path fill="#fff" d="M0 0h240v240.1H0z" />
    <g>
      <g style={styles.st12}>
        <path style={styles.st2} d="M85 25h70v15H85z" />
      </g>
      <g style={styles.st12}>
        <path
          style={styles.st2}
          d="M220 150c0-8.3-6.7-15-15-15H35c-8.3 0-15 6.7-15 15v50c0 8.3 6.7 15 15 15h170c8.3 0 15-6.7 15-15v-50z"
        />
      </g>
      <g style={styles.st12}>
        <path style={styles.st13} d="M70 50h15V25L70 40z" />
      </g>
      <g style={styles.st12}>
        <path style={styles.st13} d="M170 50V40l-15-15v25z" />
      </g>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1={119.999}
        y1={215.004}
        x2={119.999}
        y2={150.001}
      >
        <stop offset={0} style={{ stopColor: '#000000', stopOpacity: 0 }} />
        <stop offset={0.916} style={{ stopColor: '#000000', stopOpacity: 0.916 }} />
        <stop offset={1} style={{ stopColor: '#000000' }} />
      </linearGradient>
      <path
        d="M220 200c0 8.3-6.7 15-15 15H79.4L20 155.9V150h200v50z"
        style={{ mixBlendingMode: "multiply" }}
        opacity={0.2}
        fill="url(#SVGID_3_)"
      />
      <g style={styles.st12}>
        <path
          style={styles.st13}
          d="M230 140c0 8.3-6.7 15-15 15H25c-8.3 0-15-6.7-15-15V65c0-8.3 6.7-15 15-15h190c8.3 0 15 6.7 15 15v75z"
        />
      </g>
    </g>
    <g>
      <path
        style={styles.st7}
        d="M30 97.7s.3-1.7 2.6-1.7h3.9s2.2 0 2.2 1.7V122c0 1.6.7 5.8 15.9 5.8h19.5c.8 0 2 1 2 3.6 0 2.5-2 3.6-2 3.6H54.7s-24.7.1-24.7-11.9V97.7z"
      />
      <g style={styles.st12}>
        <path
          style={styles.st7}
          d="M135 165c0 2.8-2.2 5-5 5h-20c-2.8 0-5-2.2-5-5v-20c0-2.8 2.2-5 5-5h20c2.8 0 5 2.2 5 5v20z"
        />
      </g>
    </g>
  </svg>
	)
}

export default Work;