import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

import TimerIcon from 'components/LightIcons/TimerLight';

import { RECORD_STATUS } from 'LixaniAPI/enums';
import TimerContent from './components/TimerContent/TimerContent';
import RecordItem from './components/RecordItem/RecordItem';
import ClockOutDialog from 'components/ClockOutDialog/ClockOutDialog';

const styles = theme => ({
	root: {
		display: 'inherit',
		'&:hover': {
			color: 'hsla(225,75%,25%,1)',
			[theme.breakpoints.up('sm')]: {
				color: 'hsla(225,75%,25%,0.75)',
			}
		}
	},
	rootOpen: {
		color: 'hsla(225,75%,25%,1)',
		[theme.breakpoints.up('sm')]: {
			color: 'hsla(225,75%,25%,0.75)',
		}
	},
	iconButton: {
		height: 40,
		width: 40,
		[theme.breakpoints.up('sm')]: {
			margin: '0 4px',
		}
	},
	badge: {
		bottom: '-4px',
		height: 16,
		lineHeight: '100%',
		right: '-4px',
		top: 'initial',
		width: 16,
	},
	badgeEmpty: {
		display: 'none',
	},
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class TimerButton extends Component {
	state = {
		open: false,
		clockOutDialog: false,
		record: {}
	}

	handleOpen = () => {
		this.setState({	open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleClickClockOut = record => {
		this.setState({ record: record, clockOutDialog: true });
	}

	handleCloseClockOutDialog = () => {
		this.setState({ record: {}, clockOutDialog: false, open: false });
	}

	handleClockOut = record => {
		const now = new Date().toISOString();
		let clockOut = {
			'id': record.id,
			'description': record.description,
			'clockOutAt': now,
			'status': RECORD_STATUS.OUT
		}
		if (record.project) {
			clockOut.project = record.project.id;
		}
		this.props.onClockOut(clockOut, record);
	}

	timerButton = null;

	render() {
		const { classes, data } = this.props;
		const { open } = this.state;
		let networkStatus = data ? data.networkStatus : 7
		const records =  data ? (data.listRecords && data.listRecords.items) : [];

		//console.log('TimerButton data:', data);

		const timerContent = (
			<TimerContent records={records} onClose={this.handleClose}>
				{[].concat(records || []).map(item => (
					<RecordItem key={item.id} record={item} onClickClockOut={() => this.handleClickClockOut(item)} />
				))}
			</TimerContent>
		);

		const badge = (
			networkStatus !== 7 ?
				<Badge
					badgeContent={<CircularProgress color="inherit" size={12} />}
					classes={{ badge: classes.badge }}
				>
					<TimerIcon />
				</Badge>
				: <Badge badgeContent={records.length} color="secondary"
					classes={records.length > 0 ? { badge: classes.badge } : { badge: classes.badgeEmpty }}
				>
					<TimerIcon />
				</Badge>
		)

		return (
			<div className={clsx(classes.root, open && classes.rootOpen)}>
				<Hidden xsDown>
					<IconButton
						className={classes.iconButton}
						color="inherit"
						ref={node => {this.timerButton = node}}
						onClick={this.handleOpen}
						title="Notifications"
					>
						{badge}
					</IconButton>
					<Drawer
						anchor="right"
						classes={{ paper: classes.drawerPaper }}
						onClose={this.handleClose}
						open={open}
					>
						{timerContent}
					</Drawer>
				</Hidden>
				<Hidden smUp>
					<IconButton
						className={classes.iconButton}
						color="inherit"
						onClick={this.handleOpen}
						title="Notifications"
					>
						{badge}
					</IconButton>
					<Dialog
						fullScreen
						open={this.state.open}
						onClose={this.handleClose}
						TransitionComponent={Transition}
					>
						{timerContent}
					</Dialog>
				</Hidden>
				{this.state.clockOutDialog &&
					<ClockOutDialog
						onClockOut={this.handleClockOut}
						onClose={this.handleCloseClockOutDialog}
						open={this.state.clockOutDialog}
						record={this.state.record}
					/>
				}
			</div>
		);
	}
}

TimerButton.defaultProps = {
	classes: {},
	onClockOut: () => null,
	records: [],
}

TimerButton.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.object,
	onClockOut: PropTypes.func.isRequired,
	records: PropTypes.array.isRequired,
}

export default withStyles(styles)(TimerButton);