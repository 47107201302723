import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  appBar: {
    color: 'hsla(225,75%,25%,0.5)',
    position: 'fixed',
    backgroundColor: '#FFF',
    /* [theme.breakpoints.up('md')]: {
      background: 'initial',
      paddingLeft: 96,
    } */
  },
});

const appBar = (props) => {
  const { classes, children } = props;

  return (
    <AppBar
      className={classes.appBar}
      elevation={0}>
      {children}
    </AppBar>
  )
}

appBar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(appBar);
