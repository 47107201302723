import React from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { MuiThemeProvider } from '@material-ui/core/styles';

import Layout from 'components/Layout/Layout';
//import Projects from './Projects/Projects';
import lightTheme from 'components/theme/lightTheme';
//import darkTheme from 'components/theme/darkTheme';

import loadComponent from 'utils/loadComponent';

const LoadableMain = loadComponent(() => import('./Main/Main'));
const LoadableProject = loadComponent(() => import('./Project/Project'));
const LoadableUser = loadComponent(() => import('./User/User'));
const LoadableSaldo = loadComponent(() => import('./Saldo/Saldo'));


const routes = [
  { path: '/', name: 'Main', Component: LoadableMain},
  { path: '/project', name: 'Project', Component: LoadableProject},
  { path: '/user', name: 'User', Component: LoadableUser},
  { path: '/saldo', name: 'Saldo', Component: LoadableSaldo}

];

const theme = lightTheme;

function App(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
          <Switch>
            {routes.map(({ path, name, Component }, key) => (
              <Route
              exact
              path={path}
              key={key}
              render={props => {
                const crumbs = routes.filter(({ path }) => props.match.path.includes(path));
                return (
                  <Layout crumbs={crumbs}>
                    <Component {...props} />
                  </Layout>
                );
              }}
            />))}
          </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  props: PropTypes.object,
  match: PropTypes.object,
}

export default App;
