Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('@material-ui/core/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M6.5,18c0,0,0-5.6,0-7.5C6.5,7.2,9.6,5,12,5c2.5,0,5.5,2.3,5.5,5.5c0,1.9,0,7.5,0,7.5H6.5z M20.5,19v-1l-2-2c0,0,0-0.5,0-5.5s-5-6.3-5-6.3c0-0.7,0-2.2-1.5-2.2c-1.5,0-1.5,1.5-1.5,2.2c0,0-5,1.3-5,6.3c0,0.5,0,5.5,0,5.5l-2,2v1H20.5z M12,22c1.8,0,2-2,2-2l-4,0C10,20,10.2,22,12,22z' })
  );
};