import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { graphql } from 'react-apollo';

import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import MenuIcon from 'mdi-material-ui/Menu';

import AppButton from './components/AppButton/AppButton';
import NoteButton from './components/NoteButton/NoteButton';
import ProfileButton from './components/ProfileButton/ProfileButton';
import TimerButton from './components/TimerButton/TimerButton';

//import recordMuUpdate from 'LixaniAPI/recordMuUpdate';
//import inRecordsQuList from 'LixaniAPI/inRecordsQuList';
//import { recordResponse } from 'lixani-lib-graphql';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 48,
		paddingRight: 4,
		paddingLeft: 4,
		background: theme.palette.primary.main,
		//backgroundSize: 'cover',
		color: 'white',
		/* [theme.breakpoints.up('md')]: {
			paddingLeft: 24,
		}, */
	},
	hide: {
		display: 'none',
	},
	title: {
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			//marginLeft: 16,
		},
		//fontFamily: "Panton", //Propably will not use this font at all in main title
		fontWeight: 300,
	},
	orgButton: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		display: 'none',
		height: 40,
		width: 40,
		margin: '0 8px',
	},
}));

const orgList = [
	{
		value: 'personal',
		label: 'Personal'
	},
	{
		value: 'testorg',
		label: 'Test Organization'
	}
]

function AppBarToolbar(props) {

	const { onMobileDrawerToggle } = props;
	const [organization, setOrganization] = useState('');
	const classes = useStyles();

	const handleChange = event => {
		setOrganization(event.target.value);
	}

	return (
		<Toolbar className={classes.root}>
			<Hidden mdUp>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={onMobileDrawerToggle}
				>
					<MenuIcon />
				</IconButton>
			</Hidden>
			<Typography 
				variant="h4" 
				color="inherit" 
				noWrap
				classes={{ h4: classes.title }}
			>
				Lixani Admin
			</Typography>
			<TextField 
				select 
				placeholder="Organization" 
				margin="none"
				value={organization}
				onChange={(e) => handleChange(e)}
				className={classes.selectOrganization}
				style={{ display: 'none' }}
			>
				{orgList.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
			<TimerButton />
			<AppButton />
			<NoteButton />
			<ProfileButton />
			<IconButton className={classes.orgButton}>
				LI
			</IconButton>
		</Toolbar>
	);
}

//let listRecordsToken = null;

/* const RecordQuInList =
	graphql(inRecordsQuList.query, {
		options: {
			variables: inRecordsQuList.variables,
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'all',
		},
		props: (props) => {
			const { networkStatus } = props.data;
			const nextToken = props.data.listRecords && props.data.listRecords.nextToken;
			if (!listRecordsToken || listRecordsToken !== nextToken) {
				listRecordsToken = nextToken;
				if (nextToken && networkStatus === 7) {
					props.data.fetchMore({
						variables: { nextToken: nextToken },
						updateQuery: (prev, { fetchMoreResult }) => {
							if (!fetchMoreResult) return prev;
							return {
								...prev, listRecords: {
									...fetchMoreResult.listRecords,
									items: prev.listRecords.items.concat(fetchMoreResult.listRecords.items),
								}
							}
						}
					})
				}
			}
			if (props.data.listRecords) {
				let listRecords = {...props.data.listRecords};
				listRecords.items = props.data.listRecords.items.filter(record => record.project && record);
				props.data.listRecords = listRecords;
			}
			return { data: props.data };
		}
	})(TimerButton); */

//const RecordMuUpdate =
/* const TimerButtonCompose =
  graphql(recordMuUpdate, {
    props: (props) => ({
      onClockOut: (variables, record) => {
        props.mutate({
          variables: { ...variables, expectedVersion: record.version },
          optimisticResponse: () => ({
						updateRecord: recordResponse({...record, description: variables.description})
          }),
        })
      }
    }),
    options: {
      update: (dataProxy, { data: { updateRecord } }) => {
				const query = inRecordsQuList;
        const data = dataProxy.readQuery(query);
        data.listRecords.items = data.listRecords.items.filter(record => record.id !== updateRecord.id);
        dataProxy.writeQuery({ ...query, data: data }); */
				
				// TODO: Fix the code as it doesn't find the query from cache and returns error
        /* if (updateRecord.project) {
          const record_data = dataProxy.readQuery({
            query: recordQuList.query,
            variables: { ...recordQuList.variables, project: updateRecord.project.id }
					});
					console.log('updateRecord record_data:', record_data);
          record_data.listRecords.items = record_data.listRecords.items.filter(record => record.id !== updateRecord.id ? record : { ...updateRecord });
          dataProxy.writeQuery({
            query: recordQuList.query,
            variables: { ...recordQuList.variables, project: updateRecord.project.id },
            data: record_data,
          });
        } */
/*       }
    }
  })(RecordQuInList); */

AppBarToolbar.propTypes = {
	onMobileDrawerToggle: PropTypes.func.isRequired,
};

export default AppBarToolbar;