Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('@material-ui/core/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M18,6.9c0.6-0.6,1.2-1.2,1.8-1.8c0.2,0.3,0.5,0.5,0.7,0.8C20,6.4,19.4,7,18.7,7.7c1.9,2.3,2.6,4.8,2.1,7.7c-0.4,2.3-1.6,4.1-3.4,5.5c-3.5,2.7-8.7,2.3-11.8-0.9c-3.2-3.3-3.5-8.4-0.6-12c1.6-2,3.7-3.1,6.3-3.3C13.9,4.5,16.1,5.3,18,6.9zM20,13.6c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8C16.4,21.6,20,18,20,13.6zM9.1,1.5c2,0,4,0,6,0c0,0.3,0,0.7,0,1c-2,0-4,0-6,0C9.1,2.2,9.1,1.8,9.1,1.5zM11.5,14.5c0-2,0-4,0-6c0.3,0,0.7,0,1,0c0,2,0,4,0,6C12.2,14.5,11.9,14.5,11.5,14.5z' })
  );
};