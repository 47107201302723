import React from 'react';
import moment from 'moment';
import 'moment/locale/fi';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import ClockIcon from 'mdi-material-ui/ClockOutline';
import ClockOutIcon from 'mdi-material-ui/LogoutVariant';
import TimerIcon from 'mdi-material-ui/Timer';

import momentDiffTime from 'utils/momentDiffTime';

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.background.paper,
		borderLeft: '8px solid ' + theme.palette.primary.light,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		padding: '8px 16px 8px 16px',
	},
	project: {
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	projectKey: {
		color: theme.palette.fontColor.CA75,
		fontSize: '0.87rem',
	},
	times: {
		color: theme.palette.fontColor.CA75,
		display: 'flex',
		fontSize: '0.87rem',
		marginBottom: theme.spacing(1),
		'& span': {
			alignItems: 'center',
			display: 'flex',
			flex: 1,
		},
	},
	icon: {
		height: 18,
		marginRight: theme.spacing(1),
		width: 18,
	},
	actions: {

	},
	actionBtn: {
		height: 40,
		marginLeft: -10,
		width: 40,
	}
}));

function RecordItem(props) {

	const classes = useStyles();
	const { record, onClickClockOut } = props;
	const { t } = useTranslation('common');

	moment.locale('fi');

	const checkClockIn = (t) => {
		let clockIn = moment(record.clockInAt).format('L HH:mm');
		if (moment(record.clockInAt).format('L') === moment().format('L')) {
			clockIn = `${t('datetime.today')} ${moment(record.clockInAt).format('HH:mm')}`;
		} else if (moment(record.clockInAt).format('L') === moment().subtract(1, 'days').format('L')) {
			clockIn = `${t('datetime.yesterday')} ${moment(record.clockInAt).format('HH:mm')}`;
		}
		return clockIn;
	}

	return (
		<Paper className={classes.root} elevation={1}>
			<div className={classes.project}>
				<span className={classes.projectName}>{record.project.name}</span>
				<span className={classes.projectKey}>{'#' + record.project.projectKey}</span>
			</div>
			<div className={classes.description}>{record.description}</div>
			<div className={classes.times}>
				<span><ClockIcon className={classes.icon} />{checkClockIn(t)}</span>
				<span><TimerIcon className={classes.icon} />{momentDiffTime(record.clockInAt, record.clockOutAt, record.status)}</span>
			</div>
			<div className={classes.actions}>
				<IconButton className={classes.actionBtn} onClick={onClickClockOut}>
					<ClockOutIcon className={classes.actionIcon} />
				</IconButton>
			</div>
		</Paper>
	);
}

RecordItem.defaultProps = {
	classes: {},
	onClickClockOut: () => null,
	record: {},
};

RecordItem.propTypes = {
	classes: PropTypes.object.isRequired,
	onClickClockOut: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
};

export default RecordItem;