const initialState = {
	userSearch: {},
	projectSearch: {},
	page: 0
};

const rootReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_USER_SEARCH':
			return {
				...state,
				userSearch: action.userSearch,
			}
		case 'RESET_USER_SEARCH':
			return {
				...state,
				userSearch: {},
			}
		case 'SET_PROJECT_SEARCH':
			return {
				...state,
				projectSearch: action.projectSearch,
			}
		case 'RESET_PROJECT_SEARCH':
			return {
				...state,
				projectSearch: {},
			}
		case 'SET_MAINPAGE':
			return {
				...state,
				page: action.page
			}
		default:	
			return state;
	}
};

export default rootReducer;
