import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '100vh',
		display: 'flex',
	},
    content: {
		/* width: '100%',
		minHeight: '100%',
		marginTop: 48, */
		background: theme.palette.primary.main,
		color: 'white',
        
	},
    linkItem: {
        padding: 5
    }
}));

const Breadcrumbs = ({ crumbs }) => {

    const classes = useStyles();
    const { t } = useTranslation('common');
    /* if (crumbs.length <= 1) {
        return null;
    } */

    return (
    <div className={classes.content}>
        {crumbs.map(({ name, path }, key) => {
            return (
                key + 1 === crumbs.length ? (
                    <span key={key} className={classes.linkItem}>
                        {t(`navigation.${name.toLowerCase()}`)}
                    </span>
                ) : (
                    <Link key={key} to={path} className={classes.linkItem}>
                        {t(`navigation.${name.toLowerCase()}`)}
                    </Link>
                )
            )
        }
            
        )}
    </div>
    );
};

Breadcrumbs.propTypes = {
    crumbs: PropTypes.array.isRequired,
}

export default Breadcrumbs;