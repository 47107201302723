import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import TimerIcon from 'components/LightIcons/TimerLight';
import CloseIcon from 'mdi-material-ui/Close'

import hoursToTime from 'utils/hoursToTime';
import momentDiffHours from 'utils/momentDiffHours';

const styles = theme => ({
	root: {
		background: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: 320,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	header: {
		alignItems: 'center',
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		padding: '14px 0 24px 0',
		position: 'relative',
	},
	closeBtn: {
		color: theme.palette.common.white,
		height: 40,
		left: 4,
		position: 'absolute',
		top: 4,
		width: 40,
	},
	currentTime: {
		fontSize: '0.87rem',
		marginBottom: theme.spacing(2),
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		paddingBottom: theme.spacing(3),
	},
	timerIcon: {
		height: 64,
		width: 64,
	},
	totalHours: {
		fontSize: '1.5rem',
	},
});

class TimerContent extends Component {

	render() {
		const { classes, children, records } = this.props;

		let totalHours = 0;

		[].concat(records).forEach((item) => {
			totalHours = totalHours + momentDiffHours(item.clockInAt, item.clockOutAt, item.status);
		});

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					<IconButton className={classes.closeBtn} onClick={this.props.onClose}>
						<CloseIcon />
					</IconButton>
					<span className={classes.currentTime}>{moment().format('LLL')}</span>
					<span><TimerIcon className={classes.timerIcon} /></span>
					<span className={classes.totalHours}>{hoursToTime(totalHours)}</span>
				</div>
				<div className={classes.content}>
					{children}
				</div>
			</div>	
		);
	}
}

TimerContent.defaultProps = {
  classes: {},
	children: [],
	onClose: () => null,
  records: [],
};

TimerContent.propTypes = {
  classes: PropTypes.object.isRequired,
	children: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default withStyles(styles)(TimerContent);