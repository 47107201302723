import React from 'react';

const styles = {
	root: {
		enableBackground: 'new 0 0 240 240',
	},
	st0: {
		fill: '#4762B3',
	},
	st1: {
		clipPath: 'url(#SVGID_2_)',
		fill:'none',
		stroke: '#6B6B6B',
		strokeWidth:0.5,
		strokeMiterlimit:10,
	},
	st2: {
		fill: '#F2F2F2',
	},
	st3: {
		clipPath: 'url(#SVGID_2_)',
		fill:'none',
		stroke: '#D0D2D3',
		strokeWidth:0.5,
		strokeMiterlimit:10,
	},
	st4: {
		opacity:0.2,
		fill: '#FFFFFF',
	},
	st5: {
		display:'inline',
		fill: '#FFFFFF',
	},
	st6: {
		clipPath: 'url(#SVGID_2_)',
		fill:'none',
		stroke: '#E6E7E8',
		strokeWidth:0.5,
		strokeMiterlimit:10,
	},
	st7: {
		fill: '#2E5087',
	},
	st8: {
		opacity:0.2,
	},
	st9: {
		opacity:0.2,
		fill: 'url(#SVGID_3_)',
		adobeBlendingMode: 'multiply',
	},
	st10: {
		opacity:0.2,
		fill: 'url(#SVGID_4_)',
		adobeBlendingMode: 'multiply',
	},
	st11: {
		display:'inline',
	},
	st12: {
		clipPath: 'url(#SVGID_2_)',
		fill:'none',
		stroke: '#383838',
		strokeWidth:0.5,
		strokeMiterlimit:10,
	},
	st13: {
		fill: '#8da9db',
		filter: 'url(#black-glow)',
	},
	st14: {
		filter: 'url(#black-glow)',
	},
	st15: {
		fill: '#F2F2F2',
		filter: 'url(#black-glow)',
	},
	st16: {
		display:'none',
	},
};

function Admin () {

	return (
		<svg style={styles.root}
				xmlns="http://www.w3.org/2000/svg" 
				x="0px" y="0px" width="48px" height="48px" viewBox="0 0 240 240"
			>
			<filter  id="black-glow">
				<feColorMatrix  type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
				<feGaussianBlur  stdDeviation="2.5" result="coloredBlur"></feGaussianBlur>
				<feMerge>
					<feMergeNode  in="coloredBlur"></feMergeNode>
					<feMergeNode  in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
			<g>
				<g style={styles.st14}>
					<path style={styles.st0} d="M30,40c0,0,0,60,0,70c0,90,90,120,90,120s90-30,90-120c0-10,0-70,0-70l-90-30L30,40z"/>
				</g>
				<path style={styles.st7} d="M120,10l90,30c0,0,0,55,0,70c0,90-90,120-90,120V10z"/>
				<path style={styles.st8} d="M30,110C30,110,30,110,30,110c0,90,90,120,90,120v-2C120,228,31.4,198.4,30,110z"/>
				<path style={styles.st8} d="M120,228v2c0,0,90-30,90-120c0,0,0,0,0,0C208.6,198.4,120,228,120,228z"/>
				<polygon style={styles.st4} points="30,40 30,42 120,12 120,10 	"/>
				<polygon style={styles.st4} points="210,42 210,40 120,10 120,12 	"/>
			</g>
			<g>
				<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="181.8379" y1="213.4141" x2="109.1803" y2="87.5675">
					<stop  offset="0" style={{stopColor:'#000000',stopOpacity:0}}/>
					<stop  offset="1" style={{stopColor:'#000000'}}/>
				</linearGradient>
				<path style={styles.st9} d="M209.6,120.1L167,75.4l-18.2,11.4l-50.3,6.8l-23.6,21l2,27.7l-5.4,21.6l59.6,61.4
					C154.1,214.4,204.7,183.4,209.6,120.1z"/>
			</g>
			<g>
				<g style={styles.st14}>
					<path style={styles.st2} d="M37.7,47.1c0,0,0.2-1.1,1.6-1.1s2.4,0,2.4,0s1.4,0,1.4,1.1c0,1.1,0,14.6,0,15.2c0,1,0.4,3.6,9.9,3.6
						c9.5,0,11.7,0,12.2,0c0.5,0,1.2,0.6,1.2,2.2c0,1.6-0.6,2.3-1.2,2.3c-0.6,0-8.5,0-12.2,0c-3.7,0-15.4,0.1-15.4-7.4
						C37.7,54.2,37.7,47.1,37.7,47.1z"/>
				</g>
			</g>
			<g>
				<path style={styles.st13} d="M180.7,136.5l-13.2-9.6c0.3-2.3,0.5-4.6,0.5-7c0-2.4-0.2-4.7-0.5-7l13.2-9.6c0,0,2.8-1.6,1.2-4.4
					c-1.6-2.8-11.2-19.4-12.8-22.1c-1.6-2.8-4.4-1.2-4.4-1.2l-14.9,6.6c-3.6-2.9-7.7-5.2-12.1-7L136,59.3c0,0,0-3.2-3.2-3.2
					c-3.2,0-22.4,0-25.6,0c-3.2,0-3.2,3.2-3.2,3.2l-1.7,16.2c-4.4,1.7-8.4,4.1-12.1,7l-14.9-6.6c0,0-2.8-1.6-4.4,1.2
					c-1.6,2.8-11.2,19.4-12.8,22.1c-1.6,2.8,1.2,4.4,1.2,4.4l13.2,9.6c-0.3,2.3-0.5,4.6-0.5,7c0,2.4,0.2,4.7,0.5,7l-13.2,9.6
					c0,0-2.8,1.6-1.2,4.4c1.6,2.8,11.2,19.4,12.8,22.1c1.6,2.8,4.4,1.2,4.4,1.2l14.9-6.6c3.6,2.9,7.7,5.2,12.1,7l1.7,16.2
					c0,0,0,3.2,3.2,3.2c3.2,0,22.4,0,25.6,0c3.2,0,3.2-3.2,3.2-3.2l1.7-16.2c4.4-1.7,8.4-4.1,12.1-7l14.9,6.6c0,0,2.8,1.6,4.4-1.2
					c1.6-2.8,11.2-19.4,12.8-22.1C183.5,138.1,180.7,136.5,180.7,136.5z M120,142.4c-12.4,0-22.4-10-22.4-22.4
					c0-12.4,10-22.4,22.4-22.4c12.4,0,22.4,10,22.4,22.4C142.4,132.4,132.4,142.4,120,142.4z"/>
				<g>
					<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="159.585" y1="181.9746" x2="100.4488" y2="79.5478">
						<stop  offset="0" style={{stopColor:'#000000',stopOpacity:0}}/>
						<stop  offset="1" style={{stopColor:'#000000'}}/>
					</linearGradient>
					<path style={styles.st10} d="M136,180.7l1.7-16.2c4.4-1.7,8.4-4.1,12.1-7l14.9,6.6c0,0,2.8,1.6,4.4-1.2c1.6-2.8,11.2-19.4,12.8-22.1
						c1.6-2.8-1.2-4.4-1.2-4.4l-13.2-9.6c0.3-2.3,0.5-4.6,0.5-7c0-2.4-0.2-4.7-0.5-7l1.8-1.3l-20.5-19.9L120,81.1l-19.2,6l-15,13.7
						l-3.7,19.9l3,19.5l43.6,43.7c1.9,0,3.3,0,4,0C136,183.9,136,180.7,136,180.7z M97.6,120c0-12.4,10-22.4,22.4-22.4
						c12.4,0,22.4,10,22.4,22.4c0,12.4-10,22.4-22.4,22.4C107.6,142.4,97.6,132.4,97.6,120z"/>
				</g>
				<path style={styles.st15} d="M160.2,120c0,22.2-18,40.2-40.2,40.2s-40.2-18-40.2-40.2c0-22.2,18-40.2,40.2-40.2S160.2,97.8,160.2,120z
					M120,88.7c-17.3,0-31.3,14-31.3,31.3c0,17.3,14,31.3,31.3,31.3c17.3,0,31.3-14,31.3-31.3C151.3,102.7,137.3,88.7,120,88.7z"/>
			</g>
		</svg>
	)
}

export default Admin;