import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

const styles = theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: '6px 16px',
		color: 'rgba(0,0,0,0.54)',
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		boxShadow: theme.shadows[0],
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
	rootGradient: {
		borderBottom: 'none',
		background: theme.palette.primary.gradient,
		color: 'white',
	},
	rootPrimary: {
		borderBottom: 'none',
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	icon: {
		height: 18,
		width: 18,
		marginRight: 8,
	},
	buttons: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-end',
	}
});

const paperColors = {
	default: 'default',
	primary: 'primary',
	gradient: 'gradient',
}


const PaperHeader = props => {
	const { classes, title, icon, buttons, paperColor } = props;

	return (
		<Paper
			className={
				clsx(
					props.className,
					classes.root,
					paperColor === "primary" && classes.rootPrimary,
					paperColor === "gradient" && classes.rootGradient)
			}
		>
			{!icon ? null : React.cloneElement(icon, { className: classes.icon })}
			<Typography variant="body2" color="inherit" style={{ display: 'inline' }}>
				{title}
			</Typography>
			<div className={classes.buttons}>
				{buttons}
			</div>
		</Paper>
	)
};

PaperHeader.defaultProps = {
	paperColor: '',
	className: null,
};

PaperHeader.propTypes = {
	buttons: PropTypes.object,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	icon: PropTypes.object,
	paperColor: PropTypes.oneOf(Object.keys(paperColors)),
	title: PropTypes.string.isRequired,
};

export default withStyles(styles)(PaperHeader);