import { createMuiTheme } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
  typography: {
    fontFamily: "'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
    fontPanton: "'Panton SemiBold', ''Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
    body2: {
      fontWeight: 400
    }
  },
  palette: {
    primary: {
      light: '#7a8fe6',
      main: '#4762b3',
      dark: '#003983',
      contrastText: '#fff',
      gradient: 'linear-gradient(45deg, #4762b3, #7a8fe6)',
    },
    danger: {
      main: '#b0340a',
      light: '#e96538',
      dark: '#7a0000',
    },
    background: {
			BG0: 'hsl(1,0%,100%)',
			BG5: 'hsl(1,0%,95%)',
			BG10: 'hsl(1,0%,90%)',
			BG15: 'hsl(1,0%,85%)',
			BG20: 'hsl(1,0%,80%)',
			BG25: 'hsl(1,0%,75%)',
			BG30: 'hsl(1,0%,70%)',
			BG35: 'hsl(1,0%,65%)',
			BG40: 'hsl(1,0%,60%)',
			BG45: 'hsl(1,0%,55%)',
			BG50: 'hsl(1,0%,50%)',
			BG55: 'hsl(1,0%,45%)',
			BG60: 'hsl(1,0%,40%)',
			BG65: 'hsl(1,0%,35%)',
			BG70: 'hsl(1,0%,30%)',
			BG75: 'hsl(1,0%,25%)',
			BG80: 'hsl(1,0%,20%)',
			BG85: 'hsl(1,0%,15%)',
			BG90: 'hsl(1,0%,10%)',
			BG95: 'hsl(1,0%,5%)',
			BG100: 'hsl(1,0%,0%)',
      darkLixani: 'hsl(225,25%,20%)',
    },
		fontColor: {
			CA100: 'rgba(0,0,0,1)',
			CA75: 'rgba(0,0,0,0.75)',
			CA50: 'rgba(0,0,0,0.5)',
			CA25: 'rgba(0,0,0,0.25)',
			CA10: 'rgba(0,0,0,0.1)',
			CA7: 'rgba(0,0,0,0.075)',
			CA0: 'rgba(0,0,0,0)',
		},
		contrastDivider: 'rgba(255,255,255,0.12)',
  },
  backgrounds: {
    bg01: 'linear-gradient(0deg, hsla(225,89%,33%,0.9), hsla(225,89%,33%,0.9)), url(/images/bg02.jpeg) no-repeat right center fixed',
  },
  mixins: {
    toolbar: {
      minHeight: 48,
      '@media (min-width:600px)': {
        minHeight: 56,
      },
    }
  }
});

export default lightTheme;