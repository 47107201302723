import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from './components/AppBar/AppBar';
import AppBarToolbar from './components/AppBar/AppBarToolbar';
//import SideNav from './components/SideNav/SideNav';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '100vh',
		display: 'flex',
	},
  content: {
		width: '100%',
		minHeight: '100%',
		marginTop: 64,
		backgroundColor: theme.palette.background.default,
		/* [theme.breakpoints.up('md')]: {
			marginLeft: 96,
			maxWidth: 'calc(100vw - 96px)',  
		} */
	}
}));


function Layout(props) {

	const { children, crumbs } = props;
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
	const classes = useStyles();

	const toggleMobileDrawer = () => {
		setMobileDrawerOpen(!mobileDrawerOpen);
	}

	return (
		<div className={classes.root}>
			<AppBar>
				<AppBarToolbar
					onMobileDrawerToggle={()=>toggleMobileDrawer()}
				/>
				<Breadcrumbs crumbs={crumbs} />
			</AppBar>
			{/* <SideNav
				onMobileDrawerToggle={this.toggleMobileDrawer}
				onHandleLinkClick={this.clickLink}
				mobileDrawerOpen={state.mobileDrawerOpen} /> */}
			<main
				className={classes.content}
			>
				{children}
			</main>
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object.isRequired,
		PropTypes.array.isRequired
	]),
	crumbs: PropTypes.array
};

export default Layout;