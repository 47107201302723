import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper/Paper';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

import LixaniAdmin from 'components/AppIcons/Admin';
import LixaniWork from 'components/AppIcons/Work';
import MyLixani from 'components/AppIcons/MyLixani';
import AppsIcon from 'components/LightIcons/AppsLight';

import Config from 'Config';


const styles = theme => ({
	root: {
		display: 'inherit',
		'&:hover': {
			color: 'hsla(225,75%,25%,1)',
			[theme.breakpoints.up('sm')]: {
				color: 'hsla(225,75%,25%,0.75)',
			}
		}
	},
	rootOpen: {
		color: 'hsla(225,75%,25%,1)',
		[theme.breakpoints.up('sm')]: {
			color: 'hsla(225,75%,25%,0.75)',
		}
	},
	iconButton: {
		height: 40,
		width: 40,
		[theme.breakpoints.up('sm')]: {
			margin: '0 4px',
		}
	},
	popoverPaper: {
		borderRadius: 3,
	},
	paper: {
		borderRadius: 3,
		position: 'relative',
		maxWidth: 320,
	},
	header: {
		background: theme.palette.primary.gradient,
		height: 160,
	},
	content: {
		alignItems: 'flex-start',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 12,
		justifyContent: 'space-between',
		minHeight: 80,
		padding: 16,
	},
	appItem: {
		alignItems: 'center',
		color: theme.palette.fontColor.CA75,
		display: 'flex',
		flexDirection: 'column',
		minHeight: 80,
		justifyContent: 'center',
		textDecoration: 'none',
		width: 80,
		'&:hover': {
			borderRadius: 3,
			boxShadow: theme.shadows[1],
		}
	},
	appIcon: {
		height: 48,
		width: 48,
	},
});

class AppButton extends Component {
	state = {
		open: false,
		anchorEl: null,
	}

	handleClick = () => {
		this.setState({
			open: !this.state.open,
			// eslint-disable-next-line
			anchorEl: findDOMNode(this.appButton),
		});
	};

	handleClose = () => {
		if (!this.state.open) {
			return;
		}

		this.timeout = setTimeout(() => {
			this.setState({ open: false });
		});
	};

	appButton = null;

	render() {
		const { classes } = this.props;
		const { open, anchorEl } = this.state;

		const LixaniAdminIcon = () => (
			<span className={classes.appIcon}><LixaniAdmin /></span>
		)

		const LixaniWorkIcon = () => (
			<span className={classes.appIcon}><LixaniWork /></span>
		)

		const MyLixaniIcon = () => (
			<span className={classes.appIcon}><MyLixani /></span>
		)

		const appList = [
			{ name: 'My Lixani', url: Config.MY_LIXANI_URL, icon: () => (<MyLixaniIcon />)},
			{ name: 'Lixani Admin', url: Config.LIXANI_ADMIN_URL, icon: () => (<LixaniAdminIcon />)},
			{ name: 'Lixani Work', url: Config.LIXANI_WORK_URL, icon: () => (<LixaniWorkIcon />)},
		];

		return (
			<div className={clsx(classes.root, open && classes.rootOpen)}>
				<IconButton
					className={classes.iconButton}
					color="inherit"
					ref={node => {this.appButton = node}}
					onClick={this.handleClick}
				>
					<AppsIcon />
				</IconButton>
				<Popover
					open={open}
					anchorEl={anchorEl}
					anchorReference="anchorEl"
					anchorPosition={{ top: 0, left: 0 }}
					onClose={this.handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					transformOrigin={{ vertical: 'top', horizontal: 'center' }}
					classes={{ paper: classes.popoverPaper }}
				>
					<Paper className={classes.paper}>
						<div className={classes.content}>
							{appList.map((item, index) => (
								<a key={index} href={item.url} className={classes.appItem}>
									<span>{item.icon()}</span>
									<span>{item.name}</span>
								</a>
							))}
						</div>
					</Paper>
				</Popover>
			</div>
		);
	}
}

AppButton.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(AppButton);